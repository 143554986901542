/** Constants for the Collect Testimonial flow processes */

export const chatFlow = {
  requestName: 'What should I call you?',
  requestRating: (companyName: string) =>
    `How would you rate your experience with ${companyName}?`,
};

export const testimonial = {
  headline: (companyName: string) =>
    `Tell us what you love about ${companyName}`,
  guidance: (companyName: string) => [
    { question: 'Why did you decide to seek us out?' },
    { question: 'How was your experience?' },
    {
      question: `What excites you the most about the future now? Would you recommend ${
        companyName || 'us'
      }?`,
    },
  ],
};

export const resolutionFlow = {
  requestDetails:
    "I'm sorry to hear that your experience was less than ideal. We'd like to improve. Could you share more about what happened and how we could have done better?",
  requestDelay: (hasFeatureIncentives: boolean) =>
    hasFeatureIncentives
      ? "We'd prefer to make this right before you leave a review. We strive for 100% satisfaction. But we also don't believe in filtering reviews. You can either have your coupon now as a thank you for the feedback, or go to our public reviews page and get your coupon afterwards. Would you mind waiting to leave your review until after we've had a chance to get in touch?"
      : "We'd prefer to make this right before you leave a review. We strive for 100% satisfaction. But we also don't believe in filtering reviews. Would you mind waiting to leave your review until after we've had a chance to get in touch?",
  action: {
    delayReview: 'agreed to delay review',
    proceedWithReview: 'went to review page',
  },
};

export const shareToCompany = {
  shareRequestVideo:
    'Thank you so much! Could you do me a huge favor and share a few sentences of what you just said on our reviews page...? It would mean the world to us.',
  shareRequestWritten:
    'Thank you so much! Could you do me a huge favor and copy and paste this to our review page...? It would mean the world to us.',
};

export const shareToNetwork = {
  shareNetworks: {
    twitter: true,
    facebook: true,
    linkedin: false,
  },
  shareRequest:
    'We run our business through referrals from amazing people like yourself... Will you share your experience with our company?',
  shareText: (companyName: string, url?: string) =>
    url
      ? `I just had a great experience with ${companyName}! Check them out here: ${url}`
      : `I just had a great experience with ${companyName}!`,
};

export const incentives = {
  defaultCouponExpiration: 365,
};
